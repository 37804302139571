import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/court-case';

export default {
    get_all(payload) {
        return axios.get(`${RESOURCE_NAME}/`, {params: {person_hash: payload}})
    },
    get_all_for_work_place(payload) {
        return axios.get(`${RESOURCE_NAME}/for-work-place`, {params: {person_hash: payload}})
    },
    get_document_by_id(id) {
        return axios.post(`${RESOURCE_NAME}/get-document-by-id`, {document_id: id})
    },
    get_document_by_id_main(id) {
        return axios.post(`${RESOURCE_NAME}/get-document-by-id-main`, {document_id: id})
    },
    get_balance(payload) {
        return axios.get(`${RESOURCE_NAME}/get-balance`, {params: payload})
    },
    get_count(payload) {
        return axios.get(`${RESOURCE_NAME}/count`, {params: {person_hash: payload}})
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload)
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { "court_case_id": payload })
    }
};